<template>
  <v-app class="bg-white">
    <v-container class="position-sticky top-0 z-index-2 py-0">
      <v-row>
        <v-col cols="12" class="py-0">
          <app-bar-website> </app-bar-website>
        </v-col>
      </v-row>
    </v-container>
    <!-- your content here -->
    <router-view></router-view>
    <content-footer auth ></content-footer>
  </v-app>
</template>
<script>
import AppBarWebsite from "@/components/AppBarWebsite.vue";
import ContentFooter from "@/components/Footer.vue";
export default {
  name: "page-layout",
  components: {
    AppBarWebsite,
    ContentFooter,
  },
  data() {
    return {};
  },
  mounted() {
    //this.$crisp.load();
  },
};
</script>

<style lang="scss">
.required label::after {
  content: " *";
  color: red;
}
</style>
