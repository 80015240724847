<template>
  <v-container>
    <v-row class="justify-center d-flex mt-n16">
      <v-col lg="4" md="7" class="mx-auto">
        <v-card class="card-shadow border-radius-xl">
          <div class="card-padding text-center">
            <h5 class="text-h5 text-typo font-weight-bold">
              {{ "Request password reset" | trans }}
            </h5>
          </div>

          <div class="card-padding">
            <v-row v-if="localAlert">
              <v-col cols="12">
                <v-alert :type="localAlert.type" :color="localAlert.color">
                  {{ localAlert.message }}
                </v-alert>
              </v-col>
            </v-row>
            <v-row v-if="validationErrors">
              <v-col cols="12">
                <v-alert type="error" color="red" v-for="(error) in validationErrors" :key="error.input">
                  {{ error.message }}
                </v-alert>
              </v-col>
            </v-row>

            <v-text-field
                hide-details
                type="email"
                outlined
                background-color="rgba(255,255,255,.9)"
                color="rgba(0,0,0,.6)"
                light
                height="40"
                :placeholder="'Email' | trans"
                v-model="email"
                class="
                input-style
                font-size-input
                border border-radius-md
                placeholder-lighter
                text-color-light
                mb-4
              "
            >
            </v-text-field>


            <vue-recaptcha
                :loadRecaptchaScript="recaptchaRequired"
                ref="recaptcha"
                v-if="recaptchaRequired"
                :sitekey="recaptchaSiteKey"
                @verify="captchaVerify"
            ></vue-recaptcha>


            <v-btn
                elevation="0"
                :ripple="false"
                height="43"
                class="
                font-weight-bold
                text-uppercase
                btn-default
                bg-gradient-default
                py-2
                px-6
                me-2
                mt-6
                mb-2
                w-100
                text-white
              "
                color="#5e72e4"
                small
                @click="handleSubmit"
                :loading="isLoading"
            >{{ "Request reset" | trans }}
            </v-btn>
            <p class="text-sm text-body mt-3 mb-0">
              {{ 'Already have an account ?'|trans }}
              <router-link
                  :to="{ name: 'Login' }"
                  class="
                    text-default text-gradient-default text-decoration-none
                    font-weight-bold
                  "
              >
                {{ 'Sign in'|trans }}
              </router-link>
            </p>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import VueRecaptcha from "vue-recaptcha";

export default {
  name: "request-reset-password",
  components: {VueRecaptcha},
  data() {
    return {
      email: "",
      captcha: null,
      isLoading: false,
      localAlert: null,
      recaptchaRequired: process.env.NODE_ENV === "production",
      recaptchaSiteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY,
      validationErrors: [],
    };
  },
  mounted() {
    if (this.$store.getters.isAuthenticated) {
      this.$router.push({name: "backoffice"});
    }
  },
  computed: {
    canSubmit: function () {
      return this.email.length > 0;
    },
  },
  methods: {
    captchaVerify: function (response) {
      this.captcha = response;
    },
    handleSubmit(e) {
      e.preventDefault();
      this.localAlert = null;
      this.validationErrors = [];
      //let $this = this;
      if (!this.canSubmit) {
        this.localAlert = {
          type: "error",
          color: "red",
          message: this.$translator.trans("Fill in your email!"),
        };
        return false;
      }

      this.isLoading = true;

      let data = {
        email: this.email,
        captcha: this.captcha,
      };

      let $this = this;
      fetch(
          process.env.VUE_APP_API_URL + "/password-reset/request",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
      )
          .then((response) => response.json())
          .then((data) => {
            if (data.type !== "success") {
              this.localAlert = {
                type: "error",
                color: "red",
                message: data.message
                    ? data.message
                    : this.$translator.trans(
                        "Requesting the password reset is not possible!"
                    ),
              };

              if (typeof data.errors !== undefined) {
                for (const property in data.errors) {
                  for (const input in data.errors[property]) {

                    $this.validationErrors.push({
                      input: input,
                      message: data.errors[property][input]
                    });

                  }
                }
              }

              this.isLoading = false;
              return false;
            }

            this.localAlert = {
              type: "success",
              color:"green",
              message: data.message
                  ? data.message
                  : this.$translator.trans(
                      "Request received successfully! Check your email."
                  ),
            };
            this.isLoading = false;
          })
          .catch((error) => {
            console.error("Error:", error);
            this.localAlert = {
              type: "error",
              color: "red",
              message: error.message
                  ? error.message
                  : this.$translator.trans(
                      "Requesting the password reset is not possible!"
                  ),
            };
            this.isLoading = false;
          });
    },
  },
};
</script>
