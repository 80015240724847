import BazingaTranslator from "bazinga-translator";
import store from "../store";

var Translator = {
    init: function () {
        let languages = [ 'ro','en', 'ru', 'uk', 'pl','fr','es_ES','hu','de' ];
        fetch(process.env.VUE_APP_API_URL + "/translations/website.json?locales="+languages.join(','), {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {

                BazingaTranslator.fromJSON(data);

            })
            .catch((error) => {
                console.error("Error:", error);

            });
    },

    trans: function (id, parameters, domain, locale) {
        return BazingaTranslator.trans(id, parameters, domain, locale || store.getters.getLocale);
    },

    transChoice: function (id, number, parameters, domain, locale) {
        return BazingaTranslator.transChoice(id, number, parameters, domain, locale || store.getters.getLocale);
    },
};

export default Translator;

