import Vue from "vue";
import countries from "@/data/countries.json";

Vue.filter("countryName", function (countryCode) {
  for (let i = 0; i < countries.length; i++) {
    if (countries[i].code.toLowerCase() === countryCode.toLowerCase()) {
      return countries[i].name;
    }
  }

  return countryCode;
});
