import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth.js";
import user from "./modules/user.js";
import i18n from "./modules/i18n.js";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  state: {
    token: "default",
    route: null,
  },
  getters: {
    getRoute(state) {
      return state.route;
    },
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setCurrentRoute(state, route) {
      state.route = route;
    },
  },
  actions: {
    setToken({ commit }, token) {
      commit("setToken", token);
    },
    setCurrentRoute({ commit }, route) {
      commit("setCurrentRoute", route);
    },
  },
  modules: {
    auth,
    user,
    i18n,
  },
  strict: debug,
});
