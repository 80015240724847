import { removeAbilities, updateAbilitiesFor } from "@/services/abilities";

const state = { profile: {} };

const getters = {
  profile: (state) => state.profile,
  isProfileLoaded: (state) => !!state.profile.name,
  hasOnlyRole: (state) => (role) =>
    state.profile.roles.length <= 2 && state.profile.roles.includes(role),
  hasRole: (state) => (role) => state.profile.roles.includes(role),
};

const actions = {
  USER_REQUEST({ commit, dispatch, getters, rootGetters }, token) {
    return new Promise((resolve, reject) => {
      fetch(process.env.VUE_APP_API_URL + "/profile", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": token || rootGetters.getToken,
          "Accept-Language": rootGetters.getLocale,
        },
        //body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          commit("USER_SUCCESS", data.profile);

          if(data.profile.locale !== rootGetters.getLocale){
            dispatch("LANGUAGE_SWITCH", {locale: data.profile.locale, language: rootGetters.getLanguageFromLocale(data.profile.locale).title})
          }

          resolve();
        })
        .catch((error) => {
          console.error("Error:", error);
          commit("USER_ERROR");
          dispatch("AUTH_LOGOUT");
          reject();
        });
    });
  },
};

const mutations = {
  USER_SUCCESS(state, user) {
    state.profile = user;
    updateAbilitiesFor(user);
  },
  USER_ERROR(state) {
    state.profile = {};
    removeAbilities();
  },
  // AUTH_LOGOUT(state) {
  //     state.profile = {};
  //     removeAbilities();
  // }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
