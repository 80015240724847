import Vue from 'vue';
const state = {
    locale: localStorage.getItem('user-locale') || 'en',
    language: localStorage.getItem('user-language') || 'English',
}

// getters
const getters = {
    getLocale: (state) => {
        return  state.locale;
    },
    getLanguage: (state) => {
        return state.language;
    },
    getLanguageFromLocale: () => (locale) => {

      return getters.getAvailableLanguages().find(function(s){

          return s.id === locale;
      });
    },
    getAvailableLanguages: () => {

        return [
            {
                id: 'en',
                title: 'English',
            },
            {
                id: 'ro',
                title: 'Română',
            },
            {
                id: 'ru',
                title: 'Pусский',
            },
            {
                id: 'fr',
                title: 'Français',
            },
            {
                id: 'uk',
                title: 'українська',
            },
            {
                id: 'pl',
                title: 'Polski',
            },
            {
                id: 'es_ES',
                title: 'Español',
            },
            {
                id: 'hu',
                title: 'Húngaro',
            },
            {
                id: 'de',
                title: 'Deutsch',
            },
        ];
    }
}

// actions
const actions = {

    LANGUAGE_SWITCH({commit, rootGetters}, data) {
        return new Promise(resolve => {

            localStorage.setItem('user-locale', data.locale);
            localStorage.setItem('user-language', data.language);

            commit("LANGUAGE_SWITCH",data);

            if(!rootGetters.getAuthToken){
                return;
            }

            fetch(
                process.env.VUE_APP_API_URL +
                "/profile/language",
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "X-Auth-Token": rootGetters.getAuthToken,
                        "Accept-Language": data.locale,
                    },
                    body: JSON.stringify({locale:data.locale}),
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    if (data.type !== "success") {
                        Vue.prototype.$notify({
                            group: "global",
                            type: "error",
                            title: "Unable to change the language",
                            text: data.message ? data.message : "",
                        });

                        return false;
                    }

                    Vue.prototype.$notify({
                        group: "global",
                        type: "success",
                        title: "Language changed",
                        text: data.message ? data.message : "",
                    });


                })
                .catch((error) => {
                    console.error("Error:", error);

                    Vue.prototype.$notify({
                        group: "global",
                        type: "error",
                        title: "Unable to change the language",
                        text: error.message ? error.message : "",
                    });
                });

            resolve();
        });
    }

}

// mutations
const mutations = {
    LANGUAGE_SWITCH(state,data) {

        state.locale = data.locale;
        state.language = data.language;
    },

}

export default {
    state,
    getters,
    actions,
    mutations
}