<template>
  <v-app-bar
    absolute
    :color="background"
    height="auto"
    class="py-4 px-2 toolbar-content-padding-y-none"
    flat
  >
    <v-container>
      <v-row>
        <v-col cols="3" class="d-flex align-center">
          <p class="font-weight-bolder ms-lg-0 ms-4 mb-0 text-white text-sm">
            <a href="/">
              <v-img
                src="@/assets/logo-white-vertical.png"
                class="navbar-brand-img ms-3"
                width="200"
              >
              </v-img>
            </a>
            <!--            <a href="/" class="text-decoration-none text-white">The Path Forward</a>-->
          </p>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>
<script>
export default {
  name: "app-bar-authentication",
  components: {},
  props: {
    background: String,
  },
  data() {
    return {};
  },
  mounted() {
  },
};
</script>
