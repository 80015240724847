/*
=========================================================
* Vuetify Soft UI Dashboard PRO - v1.0.0
=========================================================

* Product Page:  https://store.vuetifyjs.com/products/vuetify-soft-ui-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import store from "./store";
import DashboardPlugin from "./plugins/dashboard-plugin";
import ThePathForwardPlugin from "./plugins/thepathforward";
import { abilitiesPlugin } from "@casl/vue";
import defineAbilityFor from "./services/abilities";
import Notifications from "vue-notification";
import VueMoment from "vue-moment";
import moment from "moment-timezone";
import VueLodash from "vue-lodash";
import lodash from "lodash";
import "./filters";
import CrispChat from "@dansmaculotte/vue-crisp-chat";
import ScrollDiv from "vue-scroll-div";
import VueGtag from "vue-gtag";
// import "./registerServiceWorker";
import Translator from "./services/translator";
import Linkify from "vue-linkify";

Vue.config.productionTip = false;

// Photoswipe Gallery
// import Photoswipe from "vue-pswipe";
// Vue.use(Photoswipe);

Translator.init();
Vue.prototype.$translator = Translator;

// plugin setup
Vue.use(DashboardPlugin);
Vue.use(ThePathForwardPlugin);
Vue.use(abilitiesPlugin, defineAbilityFor());
Vue.use(Notifications);
Vue.use(ScrollDiv,{
  useNative: true,
  viewClass: 'scroll-allow-select'
  // barStyle: {
  //   backgroundColor: 'pink', // scrollbar color
  // },
  // size: 6, // scrollbar size
  // offset: 2 // scrollbar offset
});
Vue.use(VueMoment, { moment });
Vue.use(VueLodash, { name: "custom", lodash: lodash });

Vue.directive('linkified', Linkify)

Vue.use(CrispChat, {
  websiteId: "18f20908-5739-4d06-9812-a203324e5196",
  disabled: true,
  hideOnLoad: true,
});

Vue.use(
  VueGtag,
  {
    enabled: process.env.NODE_ENV === "production",
    config: { id: "G-Q0QF3RBFCN" },
    appName: "The Path Forward",
    pageTrackerScreenviewEnabled: true,
  },
  router
);

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
  mounted() {
    if (
      this.$store.getters.isAuthenticated &&
      !this.$store.getters.isProfileLoaded
    ) {
      this.$store
        .dispatch("USER_REQUEST")
        .then(() => {
          //on success do nothing
        })
        .catch((error) => {
          this.$store.dispatch("AUTH_LOGOUT").then(() => {
            location.reload();
          });
        });
    }
  },
}).$mount("#app");
