import Vue from "vue";

import availableLanguages from "@/data/languages.json";

Vue.filter("spokenLanguage", function (value) {
  let spokenLanguage = availableLanguages.filter(function (language) {
    return language.code === value;
  })[0];

  if (!spokenLanguage) {
    return value;
  }

  return spokenLanguage.name;
});
