import Vue from "vue";
import Translator from "../services/translator";

Vue.filter("helpRequestStatus", function (value) {
  switch (parseInt(value)) {
    case 10:
      return Translator.trans("In review");
      break;
    case 20:
      return Translator.trans("Assigned");
      break;
    case 30:
      return Translator.trans("In progress");
      break;
    case 40:
      return Translator.trans("Solved");
      break;
    case 50:
      return Translator.trans("Rejected");
      break;

    case 0:
    default:
      return Translator.trans("Pending");
  }
});
