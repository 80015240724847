import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import { ability } from "@/services/abilities";

import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import FrontofficeLayout from "../views/Layout/FrontofficeLayout.vue";
import AuthBasicLayout from "../views/Layout/AuthenticationLayout.vue";
import WebsiteLayout from "../views/Layout/WebsiteLayout.vue";
import RequestResetPassword from "@/views/Website/RequestResetPassword";
import ResetPassword from "@/views/Website/ResetPassword";

//Backkoffice pages
const BackofficeDashboard = () => import("../views/Backoffice/Dashboard.vue");
const BackofficeRequestsList = () =>
  import("../views/Backoffice/Requests/List.vue");
const BackofficeVolunteersList = () =>
  import("../views/Backoffice/Volunteers/List.vue");
const BackofficeProfileOverview = () =>
  import("../views/Backoffice/Profile/ProfileOverview.vue");
const BackofficeFaq = () => import("../views/Backoffice/FAQ/Faq.vue");
const BackofficeRequestDetails = () =>
  import("../views/Backoffice/Requests/Details.vue");
const BackofficeServicesList = () =>
  import("../views/Backoffice/Services/List.vue");
//Frontoffice pages
const FrontofficeDashboard = () =>
  import("../views/Frontoffice/Profile/ProfileOverview.vue");
const FrontofficeRequestDetails = () =>
  import("../views/Frontoffice/Requests/Details.vue");
const RequesterFaq = () =>
  import("../views/Frontoffice/FAQ/Faq.vue");

//Common
const Login = () => import("../views/Website/Login.vue");
const Register = () => import("../views/Website/Register.vue");
const Landing = () => import("../views/Website/Landing.vue");
const SubmitRequest = () => import("../views/Website/SubmitRequest.vue");
const TermsAndConditions = () =>
  import("../views/Website/TermsAndConditions.vue");
const PrivacyPolicy = () => import("../views/Website/PrivacyPolicy.vue");
const NotFound = () => import("../views/NotFound.vue");
const Unauthorized = () => import("../views/Unauthorized.vue");
const EmailValidation = () => import("../views/Website/EmailValidation.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: WebsiteLayout,
    children: [
      {
        path: "/",
        name: "landing-page",
        component: Landing,
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: "/request-help",
        name: "submit-request-page",
        component: SubmitRequest,
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: "/terms-and-conditions",
        name: "terms-and-conditions-page",
        component: TermsAndConditions,
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: "/privacy-policy",
        name: "privacy-policy-page",
        component: PrivacyPolicy,
        meta: {
          requiresAuth: false,
        },
      },
    ],
  },
  {
    path: "/",
    component: AuthBasicLayout,
    children: [
      {
        path: "login",
        name: "Login",
        component: Login,
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: "/volunteer-register",
        name: "volunteer-register",
        component: Register,
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: "/email-validation/:token",
        name: "email-validation",
        component: EmailValidation,
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: "/request-password-reset",
        name: "request-password-reset",
        component: RequestResetPassword,
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: "/reset-password/:token",
        name: "reset-password",
        component: ResetPassword,
        meta: {
          requiresAuth: false,
        },
      },
    ],
  },
  {
    path: "/backoffice",
    name: "backoffice",
    redirect: function () {
      if (store.getters.hasRole("ROLE_VOLUNTEER")) {
        // if (ability.can("view", "backoffice-dashboard")) {
        return { name: "backoffice-dashboard" };
      }

      if (store.getters.hasRole("ROLE_REQUESTER")) {
        // if (ability.can("view", "backoffice-dashboard")) {
        return { name: "frontoffice-dashboard" };
      }

      return "/login";
    },
    component: DashboardLayout,
    children: [
      {
        path: "dashboard",
        name: "backoffice-dashboard",
        component: BackofficeDashboard,
        meta: {
          displayName: "Dashboard",
          groupName: "Backoffice",
          requiresAuth: true,
          is_granted: {
            action: "view",
            subject: "backoffice-dashboard",
          },
        },
      },
      {
        path: "requests",
        name: "requests-list",
        component: BackofficeRequestsList,
        meta: {
          displayName: "Requests list",
          groupName: "Backoffice",
          requiresAuth: true,
          is_granted: {
            action: "view",
            subject: "backoffice-requests-list",
          },
        },
      },
      {
        path: "volunteers",
        name: "volunteers-list",
        component: BackofficeVolunteersList,
        meta: {
          displayName: "Volunteers list",
          groupName: "Backoffice",
          requiresAuth: true,
          is_granted: {
            action: "view",
            subject: "backoffice-volunteers-list",
          },
        },
      },
      {
        path: "profile",
        name: "volunteer-profile",
        component: BackofficeProfileOverview,
        meta: {
          displayName: "Profile",
          groupName: "Backoffice",
          requiresAuth: true,
          is_granted: {
            action: "view",
            subject: "backoffice-volunteer-profile",
          },
        },
      },
      {
        path: "/volunteering-guide",
        name: "volunteering-guide",
        component: BackofficeFaq,
        meta: {
          displayName: "Volunteering guide",
          groupName: "Backoffice",
          requiresAuth: true,
          is_granted: {
            action: "view",
            subject: "backoffice-volunteering-guide",
          },
        },
      },
      {
        path: "requests/:guid",
        name: "requests-details",
        component: BackofficeRequestDetails,
        meta: {
          displayName: "Request details",
          groupName: "Backoffice",
          requiresAuth: true,
          is_granted: {
            action: "view",
            subject: "backoffice-request-details",
          },
        },
      },
      {
        path: "services",
        name: "services-list",
        component: BackofficeServicesList,
        meta: {
          displayName: "Services list",
          groupName: "Backoffice",
          requiresAuth: true,
          is_granted: {
            action: "view",
            subject: "backoffice-services-list",
          },
        },
      },
    ],
  },
  {
    path: "/requester",
    name: "frontoffice-homepage",
    redirect: "/requester/dashboard",
    component: FrontofficeLayout,
    children: [
      {
        path: "dashboard",
        name: "frontoffice-dashboard",
        component: FrontofficeDashboard,
        meta: {
          groupName: "Frontoffice",
          requiresAuth: true,
          is_granted: {
            action: "view",
            subject: "frontoffice-dashboard",
          },
        },
      },
      {
        path: "requests/:guid",
        name: "frontoffice-request-details",
        component: FrontofficeRequestDetails,
        meta: {
          groupName: "Frontoffice",
          requiresAuth: true,
          is_granted: {
            action: "view",
            subject: "frontoffice-request-details",
          },
        },
      },
      {
        path: "profile",
        name: "requester-profile",
        component: FrontofficeDashboard,
        meta: {
          displayName: "Profile",
          groupName: "Frontoffice",
          requiresAuth: true,
          is_granted: {
            action: "view",
            subject: "frontoffice-profile",
          },
        },
      },
      {
        path: "/requester-guide",
        name: "requester-guide",
        component: RequesterFaq,
        meta: {
          displayName: "Frequently asked questions",
          groupName: "Frontoffice",
          requiresAuth: true,
          is_granted: {
            action: "view",
            subject: "requester-volunteering-guide",
          },
        },
      },
    ],
  },
  {
    name: "unauthorized",
    path: "/unauthorized",
    component: Unauthorized,
    meta: {
      requiresAuth: true,
      is_granted: { action: "view", subject: "Unauthorized" },
    },
  },
  {
    path: "*",
    component: NotFound,
    meta: {
      requiresAuth: false,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return {
        selector: to.hash,
        // , offset: { x: 0, y: 10 }
      };
    }

    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else if (to.meta.is_granted) {
      // sectiunea asta e comentata pentru ca nu merge corect la refresh
      // daca nu se face refresh merge perfect
      // dar la refresh user-ul nu exista in in memorie cand se intra aici
      // si can() va returna false.
      if (!store.getters.isProfileLoaded) {
        store
          .dispatch("USER_REQUEST")
          .then(() => {
            if (
              !ability.can(
                to.meta.is_granted.action,
                to.meta.is_granted.subject
              )
            ) {
              next({
                path: "/unauthorized",
                query: { redirect: to.fullPath },
              });
            } else {
              next();
            }
          })
          .catch((error) => {
            console.log("error");
            console.log(error);
            store.dispatch("AUTH_LOGOUT").then(() => {
              next({
                path: "/login",
                query: { redirect: to.fullPath },
              });
            });
          });
      } else {
        let isGranted = ability.can(
          to.meta.is_granted.action,
          to.meta.is_granted.subject
        );

        console.log(isGranted ? "ACCESS GRANTED" : "ACCESS DENIED");
        if (!isGranted) {
          next({
            path: "/unauthorized",
            query: { redirect: to.fullPath },
          });
        } else {
          next();
        }
      }
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

// router.afterEach((to) => {
//   store.dispatch("setCurrentRoute", to.name);
// })

export default router;
