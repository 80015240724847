const state = {
  token: localStorage.getItem("user-token") || null,
  isAuthenticated: localStorage.getItem("user-token") ? true : false,
};

// getters
const getters = {
  isAuthenticated: (state) => {
    return state.isAuthenticated;
  },
  getToken: (state) => {
    return state.token || localStorage.getItem("user-token") || null;
  },
  getAuthToken: (state) => {
    return state.token || localStorage.getItem("user-token") || null;
  },
};

// actions
const actions = {
  AUTH_REQUEST({ commit, dispatch, rootGetters }, data) {
    return new Promise((resolve, reject) => {
      fetch(process.env.VUE_APP_API_URL + "/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": rootGetters.getLocale,
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.type !== "success") {
            reject({
              type: data.type,
              message: data.message
                ? data.message
                : "Authentication is not possible!",
            });

            return false;
          }

          localStorage.setItem("user-token", data.token);

          commit("AUTH_SUCCESS", data);
          // you have your token, now log in your user :)
          let authData = data;
          dispatch("USER_REQUEST", data.token).then(function () {
            resolve(authData);
          });
        })
        .catch((error) => {
          console.error("Error:", error);
          reject({ message: error.message ? error.message : "Server error!" });
        });
    });
  },

  AUTH_LOGOUT({ commit, dispatch }) {
    return new Promise((resolve) => {
      dispatch("BACKEND_LOGOUT").then(function () {});

      commit("AUTH_LOGOUT");
      localStorage.removeItem("user-token");

      resolve();
    });
  },

  BACKEND_LOGOUT() {
    return new Promise((resolve) => {
      fetch(process.env.VUE_APP_API_URL + "/logout", {
        method: "GET",
        headers: {
          "X-Auth-Token": state.token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          resolve();
        })
        .catch((error) => {
          console.error(error);
          resolve();
        });
    });
  },
};

// mutations
const mutations = {
  AUTH_LOGOUT(state) {
    state.token = null;
    state.isAuthenticated = false;
  },
  AUTH_SUCCESS(state, data) {
    state.isAuthenticated = true;
    state.token = data.token;
  },
  AUTH_ERROR(state) {
    state.isAuthenticated = false;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
