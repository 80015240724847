<template>
  <v-menu open-on-hover bottom offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          text
          v-bind="attrs"
          v-on="on"
      >
        <v-icon small>fa fa-language</v-icon>&nbsp;{{ currentLanguage }}
      </v-btn>
    </template>
    <v-list>
      <v-list-item v-for="language in languages" :key="language.id" @click="changeLanguage(language)">
        <v-list-item-title>{{ language.title }}</v-list-item-title>
      </v-list-item>
    </v-list>

  </v-menu>
</template>

<script>

import {mapGetters} from "vuex";


export default {
  name: 'LanguageSwitcher',
  props: [],
  components: {},
  data: function () {

    return {
    }
  },
  computed: {
    currentLanguage: function () {
      return this.getLanguage();
    },
    languages: function(){
      return this.getAvailableLanguages();
    }
  },
  methods: {
    ...mapGetters(["getLocale", "getLanguage", "getAvailableLanguages"]),
    changeLanguage(language) {
      this.$store.dispatch("LANGUAGE_SWITCH", {locale: language.id, language: language.title}).then(() => {
        //alert('language switched');
      });


    }
  }

}
</script>
