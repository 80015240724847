<template>
  <v-app-bar
    height="auto"
    class="blur blur-rounded shadow top-0 position-absolute my-3 py-2"
  >
    <v-container class="py-0">
      <v-row>
        <v-col cols="2" class="align-center">
          <p
            class="
              font-weight-bolder
              ms-lg-0 ms-4
              mb-0
              text-typo text-sm
              color-primary
            "
          >
            <a href="/">
              <v-img
                src="@/assets/logo-blue-vertical.png"
                class="navbar-brand-img ms-3"
                height="44"
              >
              </v-img
            ></a>
            <!--            <a href="/" class="text-decoration-none text-default">The Path Forward</a>-->
          </p>
        </v-col>

        <v-col cols="6" class="mx-auto text-center">
          <template v-if="!$vuetify.breakpoint.mobile">
                        <v-menu
                          v-for="item in links"
                          :key="item.name"
                          open-on-hover
                          offset-y
                          bottom
                          min-width="360"
                          max-width="600"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-if="item.href"
                              v-bind="attrs"
                              v-on="on"
                              :ripple="false"
                              color="transparent"
                              :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
                              class="text-typo text-capitalize ls-0 align-center"
                              depressed
                              link
                              :href="item.href"
                              :target="item.target"
                            >
                              {{ item.name }}
                            </v-btn>
                            <v-btn
                              v-else
                              v-bind="attrs"
                              v-on="on"
                              :ripple="false"
                              color="transparent"
                              :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
                              class="text-typo text-capitalize ls-0 align-center"
                              depressed
                              link
                              :to="item.link"
                            >
                              {{ item.name }}
                            </v-btn>
                          </template>

                        </v-menu>
          </template>
          <template v-if="$vuetify.breakpoint.mobile">
            <language-switcher></language-switcher>
<!--            <div id="google_translate_element"></div>-->
          </template>
        </v-col>
        <v-col cols="2" class="mx-auto text-center">
          <template v-if="!$vuetify.breakpoint.mobile">
            <language-switcher></language-switcher>
<!--            <div id="google_translate_element"></div>-->
          </template>
        </v-col>
        <v-col cols="2" class="text-right">
          <template v-if="!$vuetify.breakpoint.mobile">
            <v-btn
              :ripple="false"
              elevation="0"
              color="#fff"
              class="font-weight-bolder py-4 px-7 ms-2"
              :class="'btn-primary bg-gradient-default'"
              href="javascript:;"
              :to="{ name: 'Login' }"
              small
              rounded
            >
              Login
            </v-btn>
          </template>

          <v-btn
            v-show="$vuetify.breakpoint.mobile"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-600
              text-capitalize
              drawer-toggler
              btn-toggler-hover
              py-3
              px-6
              rounded-sm
            "
            color="transparent"
            @click="dialog = true"
          >
            <div class="drawer-toggler-inner">
              <i class="drawer-toggler-line" :class="{ 'bg-white': hasBg }"></i>
              <i class="drawer-toggler-line" :class="{ 'bg-white': hasBg }"></i>
              <i class="drawer-toggler-line" :class="{ 'bg-white': hasBg }"></i>
            </div>
          </v-btn>

          <v-dialog
            v-model="dialog"
            content-class="position-absolute top-0"
            width="95%"
          >
            <v-card class="card-shadow card-padding">
              <v-card-title
                class="
                  pt-0
                  px-4
                  card-padding
                  text-h6
                  font-weight-bolder
                  text-typo
                  justify-space-between
                  border-bottom
                "
              >
                The Path Forward

                <v-btn icon width="31" :ripple="false" @click="dialog = false">
                  <v-icon size="20" class="text-typo">fas fa-times</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text class="card-padding pb-0 d-flex flex-column px-0">
                <span v-for="item in mobileLinks" :key="item.name">
                    <v-btn
                      v-if="item.link"
                      :ripple="false"
                      text
                      class="text-typo text-capitalize ls-0"
                      depressed
                      link
                      :to="item.link"
                    >
                      {{ item.name }}
                      <v-spacer></v-spacer>
                    </v-btn>
                    <v-btn
                      v-if="item.href"
                      :ripple="false"
                      text
                      class="text-typo text-capitalize ls-0"
                      depressed
                      link
                      :href="item.href"
                      :target="item.target"
                    >
                      {{ item.name }}
                      <v-spacer></v-spacer>
                    </v-btn>
                  </span>

                <div class="border-bottom my-7"></div>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>
<script>
import LanguageSwitcher from "@/components/I18n/LanguageSwitcher";
export default {
  name: "app-bar-website",
  components: {LanguageSwitcher},
  props: {
    hasBg: Boolean,
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed:{
    links(){
      return [
        {
          name: this.$translator.trans("I need help"),
          link: { name: "submit-request-page" },
        },
        {
          name: this.$translator.trans("I want to help"),
          link: { name: "volunteer-register" },
        },
        {
          name: this.$translator.trans('Useful resources'),
          href: 'https://docs.google.com/spreadsheets/d/1-rYTaRXBdo0K3PcrEcWc_ZrRRV5JLMkU8MZHfULEu5Q',
          target: '_blank'
        },
      ];
    },
    mobileLinks(){
      return [
        {
          name: this.$translator.trans("I need help"),
          link: { name: "submit-request-page" },
        },
        {
          name: this.$translator.trans("I want to help"),
          link: { name: "volunteer-register" },
        },
        {
          name: this.$translator.trans('Useful resources'),
          href: 'https://docs.google.com/spreadsheets/d/1-rYTaRXBdo0K3PcrEcWc_ZrRRV5JLMkU8MZHfULEu5Q',
          target: '_blank'
        },
        {
          name: this.$translator.trans("Login"),
          link: { name: "Login" },
        },
      ];
    }
  }
};
</script>
