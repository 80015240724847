<template>
  <v-container>
    <v-row class="justify-center d-flex mt-n16">
      <v-col lg="4" md="7" class="mx-auto">
        <v-card class="card-shadow border-radius-xl">
          <div class="card-padding text-center">
            <h5 class="text-h5 text-typo font-weight-bold">
              {{ "Enter new password" | trans }}
            </h5>
          </div>

          <div class="card-padding" >
            <v-row v-if="localAlert">
              <v-col cols="12">
                <v-alert :type="localAlert.type" :color="localAlert.color">
                  {{ localAlert.message }}
                </v-alert>
              </v-col>
            </v-row>

            <v-text-field
              hide-details
              type="password"
              outlined
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              height="40"
              name="newPassword"
              :placeholder="'New password' | trans"
              autocomplete="off"
              v-model="newPassword"
              v-if="onGoingProcess"
              class="
                input-style
                font-size-input
                border border-radius-md
                placeholder-lighter
                text-color-light
                mb-4
              "
            >
            </v-text-field>

            <v-text-field
              hide-details
              type="password"
              outlined
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              height="40"
              name="repeatPassword"
              :placeholder="'Repeat password' | trans"
              autocomplete="off"
              v-model="repeatPassword"
              v-if="onGoingProcess"
              class="
                input-style
                font-size-input
                border border-radius-md
                placeholder-lighter
                text-color-light
                mb-4
              "
            >
            </v-text-field>

            <v-btn
                v-if="onGoingProcess"
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-bold
                text-uppercase
                btn-default
                bg-gradient-default
                py-2
                px-6
                me-2
                mt-6
                mb-2
                w-100
                text-white
              "
              color="#5e72e4"
              small
              @click="handleSubmit"
              :loading="isLoading"
              >{{ "Change password" | trans }}
            </v-btn>


            <p class="text-sm text-body mt-3 mb-0" v-if="onGoingProcess === false">
              {{ 'Go to login or you will be automatically redirected in a few seconds.'|trans }}
              <router-link
                  :to="{ name: 'Login' }"
                  class="
                    text-default text-gradient-default text-decoration-none
                    font-weight-bold
                  "
              >
                {{ 'To login'|trans }}
              </router-link>
            </p>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "reset-password",
  data() {
    return {
      newPassword: "",
      repeatPassword: "",
      isLoading: false,
      localAlert: null,
      onGoingProcess: true,
    };
  },
  mounted() {
    if (this.$store.getters.isAuthenticated) {
      this.$router.push({ name: "backoffice" });
    }
  },
  computed: {
    canSubmit: function () {
      return this.newPassword.length > 0 && this.repeatPassword.length > 0;
    },
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.localAlert = null;
      //let $this = this;
      if (!this.canSubmit) {
        this.localAlert = {
          type: "error",
          color: "red",
          message: this.$translator.trans("Fill in both fields!"),
        };
        return false;
      }
      if (this.newPassword !== this.repeatPassword) {
        this.localAlert = {
          type: "error",
          color: "red",
          message: this.$translator.trans("The two passwords do not match!"),
        };
        return false;
      }

      this.isLoading = true;
      let $this = this;
      let data = {
        newPassword: this.newPassword,
        repeatPassword: this.repeatPassword,
      };

      fetch(
        process.env.VUE_APP_API_URL +
          "/password-reset/reset/" +
          this.$route.params.token,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.type !== "success") {
            this.localAlert = {
              type: "error",
              color: "red",
              message: data.message
                ? data.message
                : this.$translator.trans(
                    "Resetting the password is not possible!"
                  ),
            };

            return false;
          }

          this.localAlert = {
            type: "success",
            color: "green",
            message: data.message
                ? data.message
                : this.$translator.trans(
                    "Password changed successfully!"
                ),
          };

          this.onGoingProcess = false;
          setTimeout(function(){
            $this.$router.push({ name: "Login" });
          }, 7000);

        })
        .catch((error) => {
          console.error("Error:", error);
          this.localAlert = {
            type: "error",
            color: "red",
            message: error.message
              ? error.message
              : this.$translator.trans(
                  "Resetting the password is not possible!"
                ),
          };
        });
    },
  },
};
</script>
