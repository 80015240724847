<template>
  <v-app>
    <v-main class="auth-pages">
      <div
        class="header-auth position-relative ma-4 pb-16 pt-16 border-radius-xl"
        :style="`background-image:  url(${require('../../assets/img/curved-images/curved6.jpg')}; background-size: cover; background-position: 50%;`"
      >
        <span
          class="mask bg-gradient-default border-radius-xl opacity-6"
        ></span>

        <v-container>
          <v-row class="d-flex mt-5">
            <v-col cols="12" md="6" class="mx-auto py-0 position-relative">
              <h1
                class="
                  text-h1 text-white
                  font-weight-bolder
                  text-center
                  mb-2
                  mt-5
                "
              >
                {{ 'Welcome'|trans }}
              </h1>
              <p
                v-if="this.$route.name === 'Login'"
                class="
                  text-white
                  font-size-root
                  text-center
                  font-weight-thin
                  mb-12
                "
              >
                {{ 'Use this form to access your account.'|trans }}
              </p>
              <p
                v-if="this.$route.name === 'Register'"
                class="
                  text-white
                  font-size-root
                  text-center
                  font-weight-thin
                  mb-12
                "
              >
                {{ 'Create a new volunteer account.'|trans }}
              </p>
              <p
                v-if="this.$route.name === 'request-password-reset'"
                class="
                  text-white
                  font-size-root
                  text-center
                  font-weight-thin
                  mb-12
                "
              >
                {{ 'Use this form to request your password reset.'|trans }}
              </p>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <app-bar-auth background="transparent" has-bg linkColor="white">
      </app-bar-auth>
      <fade-transition :duration="200" origin="center top" mode="out-in">
        <!-- your content here -->
        <v-container class="mt-n16 pb-0">
          <router-view></router-view>
          <content-footer auth v-if="!$route.meta.hideFooter"></content-footer>
        </v-container>
      </fade-transition>
    </v-main>
  </v-app>
</template>
<script>
import AppBarAuth from "@/components/AppBarAuth";
import { FadeTransition } from "vue2-transitions";
import ContentFooter from "@/components/Footer.vue";

export default {
  name: "authentication-layout",
  components: {
    AppBarAuth,
    FadeTransition,
    ContentFooter,
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {
    //this.$crisp.load();
  },
};
</script>
