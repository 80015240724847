<template>
  <span>
    <template v-if="auth">
      <v-footer color="transparent" class="mt-10 pt-10 pb-5">
        <v-card class="flex" color="transparent">
          <v-card-text class="px-0">
            <v-row>
              <v-col cols="8" class="mx-auto text-center">
                <p class="copyright text-secondary text-muted ls-0">
                  Copyright © {{ new Date().getFullYear() }}
                  "The Path Forward" project
                </p>
                <p class="copyright text-secondary text-muted ls-0">
                  Made with <i class="fa fa-heart" aria-hidden="true"></i> by <a href="https://nimasoftware.com" target="_blank"> NIMA Software</a>
                </p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-footer>
    </template>

    <template v-else>
      <v-footer color="transparent" class="px-6">
        <v-card class="flex" color="transparent">
          <v-card-text class="px-0">
            <v-row>
              <v-col cols="12" md="6">
                <div class="copyright text-body-2 ls-0 text-muted">
                  © {{ new Date().getFullYear() }}, made with
                  <i class="fa fa-heart" aria-hidden="true"></i> for "The Path
                  Forward" project by <a href="https://nimasoftware.com" target="_blank"> NIMA Software</a>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-footer>
    </template>
  </span>
</template>
<script>
export default {
  name: "content-footer",
  props: {
    auth: Boolean,
  },
  data() {
    return {};
  },
};
</script>
