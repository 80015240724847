import { Ability, AbilityBuilder } from "@casl/ability";

var ability;

// const ROLE_USER = "ROLE_USER";
const ROLE_ADMIN = "ROLE_ADMIN";
const ROLE_SUPER_ADMIN = "ROLE_SUPER_ADMIN";
const ROLE_VOLUNTEER = "ROLE_VOLUNTEER";
const ROLE_REQUESTER = "ROLE_REQUESTER";

export default function defineAbilityFor(user) {
  const { can, cannot, rules } = new AbilityBuilder(Ability);

  cannot("manage", "all");

  if (user) {
    can("view", "Unauthorized");

    if (user.roles.includes(ROLE_VOLUNTEER)) {
      can("view", "backoffice-dashboard");
      can("view", "backoffice-request-details");
      can("view", "backoffice-volunteering-guide");
      can("view", "backoffice-volunteer-profile");
      can("view", "backoffice-volunteers-list");
      can("view", "backoffice-requests-list");
      can("view", "backoffice-services-list");
    }

    if (user.roles.includes(ROLE_REQUESTER)) {
      can("view", "frontoffice-dashboard");
      can("view", "frontoffice-profile");
      can("view", "frontoffice-request-details");
      can("view", "requester-volunteering-guide");
    }

    if (
      user.roles.includes(ROLE_ADMIN) ||
      user.roles.includes(ROLE_SUPER_ADMIN)
    ) {
      can("manage", "all");
      cannot("view", "frontoffice-dashboard");
      cannot("view", "frontoffice-profile");
      cannot("view", "frontoffice-request-details");
      cannot("view", "requester-volunteering-guide");
    }
  }

  if (ability) {
    ability.update(rules);
  } else {
    ability = new Ability(rules);
  }

  return ability;
}

function removeAbilities() {
  ability.update([]);
}

function updateAbilitiesFor(user) {
  if (!user) {
    console.error("You must pass in an user!");
    return;
  }

  defineAbilityFor(user);

  // v-if="$can('view', 'Users')"
}

export { updateAbilitiesFor, removeAbilities, ability };
